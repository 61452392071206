@charset "utf-8";

/* ----------------------------------------------------------------
   component/menu
/  --------------------------------------------------------------*/

.menu {
  margin-bottom: 80px;
  &__section {
    margin-top: 70px;
    margin-bottom: 80px;
  }
  .headline {
    margin-top: 60px;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 0 0 49%;
      margin-bottom: 10px;
      padding: 10px 20px 10px 30px;
      background: #fff;
      border-left: 8px solid #f7d6c9;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      &.long {
        flex: 0 0 100%;
      }
    }
  }
  &__target {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    background: url(../img/share/line_headline.png) left bottom repeat-x;
    &-item {
      width: 248px;
    }
    &-title {
      display: flex;
      align-items: center;
      height: 268px;
      margin: 0 0 15px;
      padding-left: 20px;
      font-size: rem(18);
      background-repeat: no-repeat;
      box-sizing: border-box;
      line-height: 1;
    }
    .-target01 {
      background-image: url(../img/menu/img_target01.jpg);
    }
    .-target02 {
      background-image: url(../img/menu/img_target02.jpg);
    }
    .-target03 {
      background-image: url(../img/menu/img_target03.jpg);
    }
    .-target04 {
      background-image: url(../img/menu/img_target04.jpg);
    }
  }
  &__set {
    padding: 40px 0;
    background: #fff;
    .contents-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title {
      width: 275px;
      margin: 0;
      margin-right: 25px;
      padding: 60px 55px;;
      font-size: rem(18);
      background: url(../img/top/bg_profile_photo.png) no-repeat;
      background-size: 100%;
      text-align: center;
      box-sizing: border-box;
    }
    &-list {
      list-style: none;
      //width: 30%;
      width: 575px;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        width: 151px;
        height: 151px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-repeat: no-repeat;
        font-size: rem(18);
        text-align: center;
        &:after {
          position: absolute;
          right: -62px;
          top: 35%;
          content: "×";
          font-size: 62px;
          color: #d5d5d5;
          line-height: 1;
        }
        &:last-child:after {
          display: none;
        }
      }
      .-set01 {
        background-image: url(../img/menu/img_set01.png);
      }
      .-set02 {
        background-image: url(../img/menu/img_set02.png);
      }
      .-set03 {
        background-image: url(../img/menu/img_set03.png);
      }
    }
  }
@include media(tab) {
  &__set {
    .contents-inner {
      //flex-direction: column;
    }
    &-title {
      width: auto;
    }
    &-list {
      li {
        width: 100px;
        height: 100px;
        background-size: contain;
        &:after {
          right: -38px;
          font-size: rem(40);
        }
      }
    }
  }
}
@include media(sp) {
  margin-bottom: 30px;
  &__section {
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .headline {
    margin-top: 30px;
  }
  &__list {
    flex-direction: column;
  }
  &__target {
    flex-direction: column;
    &-item {
      //display: flex;
      width: auto;
    }
    &-title {
      font-size: rem(14);
      height: 100px;
      padding-left: 50%;
      background-position: left center;
      background-size: contain;
    }
  }
  &__set {
    .contents-inner {
      flex-direction: column;
    }
    &-title {
      margin: 0 0 20px;
      background: none;
      padding: 0;
    }
    &-list {
      width: 100%;
      justify-content: center;
      li {
        width: 81px;
        height: 81px;
        margin-right: 20px;
        font-size: rem(12);
        background-size: cover;
        &:last-child {
          margin: 0;
        }
        &:after {
          right: -20px;
          top: 30px;
          font-size: rem(20);
        }
      }
    }
  }
}
}


.menu-detail {
  display: flex;
  justify-content: space-between;
  .section-title {
    margin-top: -15px;
    margin-bottom: 20px;
    font-size: rem(38);
    line-height: 1.4;
  }
  &__text {
    width: 60%;
    margin: -15px;
    background: url(../img/top/bg_shop_info.png) left top no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    p {
      width: 50%;
      margin: 0 auto;
    }
  }
  &__img {
    margin: 0;
  }
  &__link {
    margin-top: 30px !important;
    a {
      display: block;
      padding: 15px 20px;
      text-align: center;
      color: #fff;
      background: #cd3f5a;
      font-size: rem(20);
      &:after {
        content: "→";
      }
    }
  }
@include media(tab) {
  align-items: center;
  .section-title {
    font-size: rem(30);
  }
  &__text {
    padding-bottom: 80px;
  }
  &__img {
    width: 40%;
  }
}
@include media(sp) {
  flex-direction: column;
  .section-title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: rem(20);
    line-height: 1.4;
  }
  &__text {
    width: auto;
    margin: 0;
    padding-bottom: 0;
    background: none;
    color: #000;
    p {
      width: auto;
    }
  }
  &__img {
    width: auto;
    margin: 20px auto 0;
  }
  &__link {
    margin-top: 30px !important;
    a {
      padding: 15px;
      color: #aa4451;
      background: url(../img/share/line_headline.png) repeat-x, url(../img/share/line_headline.png) left bottom repeat-x;
      font-size: rem(16);
      &:after {
        content: "→";
      }
    }
  }
}
}

.price-table {
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 10px 30px;
    background: #f7d6c9;
    text-align: left;
    border-bottom: 14px solid #fff7f3;
    width: 60%;
    box-sizing: border-box;
  }
  td {
    background: #fff;
    padding: 10px 30px;
    border-bottom: 14px solid #fff7f3;
    color: #000;
  }
@include media(sp) {
  th {
    display: block;
    width: 100%;
    padding: 10px;
    border: 0;
  }
  td {
    display: block;
    padding: 10px;
    border: 0;
    text-align: center;
  }
}
}
