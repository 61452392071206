@charset "utf-8";

/* ----------------------------------------------------------------
   layout/back2top
/  --------------------------------------------------------------*/

.back2top {
  position: relative;
  width: 900px;
  right: -50px;
  top: -70px;
  margin: 0 auto;
  text-align: right;
  a {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 89px;
    height: 74px;
    background: url(../img/share/bg_pagetop.png) no-repeat;
    font-size: rem(17);
    text-align: center;
    line-height: 4em;
  }
  @media screen and (max-width: 1024px) {
    right: 10px;
  }
  @include media(sp) {
    display: none;
  }
}
