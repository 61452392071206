@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-intro
/  --------------------------------------------------------------*/

.top-introduction {
  position: relative;
  background: #fff7f3;
  text-align: center;
  padding-bottom: 55px;
  &:before {
    position: absolute;
    top: -40px;
    display: block;
    content: " ";
    width: 100%;
    height: 40px;
    background: url(../img/top/bg_info.png) no-repeat;
    background-size: 100% 100%;
  }
  p {
    line-height: 3;
    //font-weight: bold;
  }
  &__inner {
    //display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__text {
    display: inline-block;
    position: relative;
    //padding-left: 140px;
    margin-right: 20px;
    &:before {
      position: absolute;
      bottom: 0;
      left: -140px;
      display: block;
      content: " ";
      width: 153px;
      height: 244px;
      background: url(../img/top/img_introduction.png) no-repeat;
    }
  }
  &__img {
    position: absolute;
    right: -300px;
    bottom: 0;
    width: 277px;
    height: 282px;
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translate(-50%, -50%);
      width: auto;
      max-width: inherit;
      height: 100%;
    }
  }
@media screen and (max-width: 1024px) {
  &__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 20px;
    &:before {
      display: none;
    }
    .section-title {
      flex: 0 0 100%;
    }
  }
  &__img {
    position: relative;
    right: auto;
  }
}
/* for sp  */
@include media(sp) {
  padding: 20px 40px;
  &:before {
    display: none;
  }
  &__inner {
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .section-title {
    order: 1;
    margin: 0 0 20px;
    margin-top: -1em;
  }
  p {
    order: 2;
    line-height: 1.6;
  }
  &__img {
    order: 0 !important;
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    img {
      height: auto;
      max-width: 100%;
      position: static;
      transform: none;
    }
  }
}
}