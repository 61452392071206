@charset "utf-8";

/* ----------------------------------------------------------------
   theme/photo
/  --------------------------------------------------------------*/

.photo {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      width: 48%;
      margin-bottom: 80px;
    }
  }

@include media(sp) {
  &__list {
    margin: 0 auto;
    li {
      width: 47%;
      margin-bottom: 10px;
      margin-right: 10px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

}

}
