@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-service
/  --------------------------------------------------------------*/

.top-service {
  padding: 120px 0 200px;
  background: #fff7f3;
  &__section {
    margin-bottom: 70px;
  }
  &__headline {
    padding-left: 20px;
    &:before {
      display: inline-block;
      content: " ";
      width: 42px;
      height: 30px;
      background: url(../img/share/icon_camera.png) no-repeat;
      margin-right: 25px;
    }
  }
  &__lesson-text {
    margin-bottom: 30px;
    padding-left: 90px;
  }
  &__img-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
@include media(sp) {
  padding: 40px 0 1px;
  &__section {
    margin-bottom: 30px;
  }
  .menu-detail__img {
    display: none;
  }
  &__headline {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &__lesson-text {
    padding-left: 0;
    color: #000;
  }
}
}