@charset "utf-8";

/* ----------------------------------------------------------------
   layout/footer
/  --------------------------------------------------------------*/

.footer {
  position: relative;
  padding: 30px 0;
  background: #f7dacf;
  &__inner {
    position: relative;
    width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media screen and(max-width: 1024px) {
      width: auto;
    }
  }
  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 110px;
    width: 60%;
    box-sizing: border-box;
  }
  &__img {
    position: absolute;
    left: 0;
    width: 94px;
    height: 94px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 70px;
    }
  }
  &__logo {
    order: 2;
    margin: 0;
  }
  &__description {
    order: 1;
    margin: 0 0 5px;
    font-size: rem(12);
    font-weight: bold;
  }
  &__shop-info {
    width: 40%;
    margin: 0;
    padding-left: 17px;
    border-left: 1px dotted #b75e67;
    box-sizing: border-box;
    font-size: rem(14);
    dt {
      float: left;
      clear: left;
    }
    .open {
      float: none;
    }
    dd {
      &:before {
        content: "：";
      }
    }
  }
  &__sns-list {
    list-style: none;
    margin: 45px 0 10px;
    padding: 0;
    display: flex;
    justify-content: center;
    line-height: 0;
    li {
      margin-right: 18px;
      &:last-child {
        margin: 0;
      }
      img {
        width: 32px;
      }
    }
  }

@include media(sp) {
  padding-top: 0;
  padding-bottom: 70px;
  background: #fff7f3;
  &__inner {
    position: static;
    width: auto;
  }
  &__info {
    position: static;
    width: auto;
    flex: 0 0 50%;
    padding: 0;
    border: 1px solid #d4d4d4;
    border-left: 0;
    box-sizing: border-box;
  }
  &__img {
    bottom: 0;
    left: 15px;
    width: 43px;
    height: 43px;
    img {
      width: 30px;
    }
  }
  &__logo {
    position: absolute;
    bottom: 0;
    left: 70px;
    width: 100px;
  }
  &__description {
    width: auto;
    border: 0;
    padding: 5px;
    font-size: rem(10);
    font-weight: normal;
  }
  &__shop-info {
    width: auto;
    padding: 5px;
    border: 1px solid #d4d4d4;
    border-left: 0;
    border-right: 0;
    color: #000;
    flex: 0 0 50%;
    box-sizing: border-box;
    font-size: rem(10);
    dt {
      width: auto;
    }
    dd {
      margin: 0;
    }
  }
  &__contents {
    position: relative;
  }
  &__sns-list {
    justify-content: flex-end;
    margin: 20px 0;
    margin-right: 15px;
    li {
      margin-right: 5px;
    }
  }
}
}

.copyright {
  font-size: rem(14);
  text-align: center;
  font-family: 'Noto Sans Japanese', Sans-Serif;
  font-weight: normal;
  line-height: 1;
@include media(sp) {
  background: #f7dacf;
  padding: 5px;
}
}

.reserve {
  padding: 30px 0 80px;
  background: #fff7f3;
  .flex-contents {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__description {
    margin-bottom: 35px;
    text-align: center;
  }
  &__section {
    position: relative;
    width: 49.6%;
    margin-bottom: 7px;
    padding: 25px 35px 20px;
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    p {
      margin: 0;
      color: #313131;
    }
    a {
      color: #313131;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__title {
    margin: 0 0 15px;
    text-align: center;
    font-size: rem(18);
  }
  /* tel */
  .-tel {
    .reserve__text {
      padding-left: 50px;
      &:before {
        position: absolute;
        display: inline-block;
        content: " ";
        width: 56px;
        height: 86px;
        background: url(../img/share/img_phone.png) no-repeat;
        left: 20px;
        top: 35px;
      }
    }
  }
  /* mail */
  .-mail {
    display: block;
    .reserve__text {
      text-align: left;
      padding-left: 80px;
      &:before {
        position: absolute;
        display: inline-block;
        content: " ";
        width: 89px;
        height: 89px;
        background: url(../img/share/img_mail.png) no-repeat;
        left: 10px;
        top: 35px;
      }
    }
  }
  /* sns */
  .-sns {
    padding-left: 70px;
    padding-right: 70px;
    .reserve__section-inner {
      width: 100%;
    }
  }
  &__sns-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    color: #313131;
    li {
    }
    a {
      text-decoration: none;
    }
    img {
      width: 50px;
    }
  }
  /* text */
  .-text {
    background: #f7dacf;
    font-size: rem(18);
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #aa4451;
    }
  }
@include media(sp) {
  padding-bottom: 30px;
  .contents-inner {
    width: 100%;
  }
  .flex-contents {
    flex-direction: column;
  }
  &__description {
    padding: 0 20px;
  }
  &__section {
    width: 100%;
    margin: 0;
    padding: 20px;
    background: #fff url(../img/share/line_headline.png) left top no-repeat;
    a {
      color: #aa4451;
      text-decoration: underline;
    }
  }
  &__title {
    background: #fff7f3;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
    font-size: rem(16);
  }
  .-tel {
    .reserve__text {
      position: relative;
      padding-left: 40px;
      &:before {
        top: 50%;
        left: 0;
        width: 28px;
        height: 43px;
        margin-top: -21px;
        background-size: contain;
      }
    }
  }
  .-mail {
    .reserve__text {
      position: relative;
      padding-left: 60px;
      margin-bottom: 10px;
      &:before {
        width: 44px;
        height: 44px;
        background-size: contain;
        top: 50%;
        left: 0;
        margin-top: -22px;
      }
    }
  }
  .-sns {
    justify-content: flex-start;
    padding: 20px;
  }
  .-text {
    display: block;
    background: none;
    margin-top: 30px;
    padding: 0;
    p {
      display: inline-block;
      padding: 10px 20px;
      background: #9d9896;
      color: #fff;
      font-size: rem(16);
      border-radius: 20px;
    }
  }
}
}
