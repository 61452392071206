@charset "utf-8" ;

// ----------------------------------------------------------------
// function.scss - Event.scss
// -----------------------------------------------------------------

///
// rem
// @param num
// @return rem
///
@function rem($px) {
  @return $px / 16 * 1rem;
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
