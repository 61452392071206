@charset "utf-8";

/* ----------------------------------------------------------------
   component/contents-title
/  --------------------------------------------------------------*/

.contents-title {
  margin: 0;
  padding: 35px;
  text-align: center;
  background: url(../img/share/bg_title.png) no-repeat;
  background-size: cover;
  color: #fff;
  font-size: rem(40);
  &:before,
  &:after {
    display: block;
    content: " ";
    width: 273px;
    height: 39px;
    margin: 0 auto 60px;
    background: url(../img/share/title_frame.png) no-repeat;
  }
  &:after {
    margin: 60px auto 0;
    transform: rotate(180deg);
  }
@include media(sp) {
  padding: 30px 20px;
  font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
  font-size: rem(20);
  &:before,
  &:after {
    display: none;
  }
}
}

.section-title {
  margin-bottom: 40px;
  font-size: rem(40);
  text-align: center;
  line-height: 1;
  &__ruby {
    font-size: rem(16);
  }
  &.-type-r {
    &:before {
      display: block;
      content: " ";
      width: 341px;
      height: 106px;
      margin: 0 auto 15px;
      background: url(../img/share/ribbon.png) no-repeat;
    }
  }
@include media(sp) {
  margin-bottom: 20px;
  color: #fff;
  font-size: rem(22);
  font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
  span {
    display: inline-block;
    min-width: 60%;
    padding: 7px 10px;
    background: #aa4451;
    &.section-title__ruby {
      display: none;
    }
  }
  &.-type-r {
    &:before {
      width: 170px;
      height: 54px;
      background-size: contain;
    }
  }
}

}
