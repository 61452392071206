@charset "utf-8" ;

/* ----------------------------------------------------------------
   utility/text
/  --------------------------------------------------------------*/

.u-text__strong {
  font-weight: bold;
}

.u-text__transform.--upper {
  text-transform: uppercase;
}

.u-text__post-positional-particle {
  font-size: 72.72727273%; // 16px in case of parent elements font-size: 22px;
}

.u-text__left {
  text-align: left;
}

.u-text__right {
  text-align: right;
}

.u-mb__0 {
  margin-bottom: 0 !important;
}

.no-style {
  list-style: none;
  padding: 0;
  margin: 0;
}
