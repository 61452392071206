@charset "utf-8";
/* -----------------------------------------------------------
	header
----------------------------------------------------------- */
/* 
	for pc
----------------------------------------------------------- */
.header {
  background: #fff7f3;
  &__inner {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px 0;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__logo {
    position: relative;
    margin: 0;
    margin-right: 20px;
    line-height: 0;
    &-text {
      position: absolute;
      top: 10px;
    }
  }
  &__shop-info {
    margin: 0 5% 0 45px;
    width: 30%;
    @media screen and (max-width: 1024px) {
      width: 25%;
    }
    font-size: rem(11);
    color: #5c514d;
    font-family: 'Noto Sans Japanese', Sans-Serif;
    font-weight: normal;
    dt {
      float: left;
      clear: left;
      width: 3.3em;
      &:first-child {
        width: auto;
        margin-right: 1em;
      }
    }
    dd {
      margin-left: 0;
      &:before {
        margin-right: 0.2em;
        content: "/";
      }
    }
    .open {
      margin-bottom: 5px;
      padding-bottom: 5px;
      background: url(../img/share/line.png) left bottom repeat-x;
    }
  }
  &__sns {
    //right: 20%;
    //position: absolute;
    //margin-left: auto;
    margin-right: -10%;
    &-title {
      font-size: rem(11);
      color: #5c514d;
      font-family: 'Noto Sans Japanese', Sans-Serif;
      font-weight: normal;
      border-bottom: 1px solid #5c514d;
      line-height: 1;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      img {
        width: 32px;
      }
    }
  }
}

/* nav */
.header-nav {
  width: 100%;
  padding: 7px 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Noto Sans Japanese', Sans-Serif;
  font-weight: normal;
  font-size: rem(14);
  &__title {
    margin: 0;
    color: #ec6d81;
    font-weight: normal;
    font-size: rem(14);
    &:after {
      content: "|";
      margin: 0 6px;
      color: #313131;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    a {
      color: #313131;
    }
    li{
      &:after {
        content: "|";
        margin: 0 6px;
        color: #313131;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &.-menu a {
        color: #ec6d81;
      }
    }
  }

}

/* 
	for sp
----------------------------------------------------------- */
@include media(tab) {
.header {
  &__inner {
    display: block;
    padding-bottom: 0;
    overflow: hidden;
  }
  &__logo {
    margin: 0 10px 10px;
    a {
      display: flex;
      align-items: center;
    }
    .-logo {
      order: 2;
      width: 100px;
    }
    .-img {
      order: 1;
      width: 40px;
    }
    &-text {
      display: none;
    }
  }
  &__shop-info {
    //display: flex;
    margin: 0;
    width: auto;
    dt {
      width: auto;
      padding: 5px 5px 5px 10%;
    }
    dd {
      padding: 5px 0;
      color: #ec6d82;
      background: url(../img/share/line_headline.png) left bottom repeat-x;
      background-size: 100% 2px;
      &:before {
        
      }
    }
    dt.open {
      float: none;
      background: #ec6d82;
      color: #fff;
      width: 100%;
      margin: 0;
      padding: 5px 0;
      text-align: center;
    }
    dd.open {
      display: none;
    }
  }
  &__sns {
    display: none;
  }
}

.menu-trigger {
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 19px;
  //transition: all .4s;
  box-sizing: border-box;
  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #636363;
    border-radius: 4px;
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 8px;
    }
    &:nth-of-type(3) {
      top: 16px;
    }
    &.menu {
      background: none;
      height: auto;
      top: 20px;
      font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
      color: #636363;
      font-size: 10px;
    }
  }
  &.open {
    span:nth-of-type(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
    }
    span:nth-of-type(3) {
      transform: translateY(-8px) rotate(45deg);
    }
    .menu {
      display: none;
    }
  }

  
}

.header-utility-nav {
  display: none;
}
.header-nav {
  display: block;
  position: fixed;
  top: 0;
  padding: 0;
  width: 80%;
  height: 100%;
  right: -80%;
  text-align: left;
  background: #aa4451;
  z-index: 100;
  &__title {
    padding: 15px;
    color: #fff;
    &:after {
      display: none;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 3px 0 0;
    flex-direction: column;
    background: #fff url(../img/share/line_headline.png) left top repeat-x;
    li {
      background: #fff url(../img/share/line_headline.png) left bottom repeat-x;
      &:after {
        display: none;
      }
    }
    a {
      position: relative;
      display: block;
      padding: 15px;
      &:after {
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -12px;
        display: inline-block;
        font-family: FontAwesome;
        content: "\f0da";
        color: #fff;
      }
    }
  }
}

body.open {
  position: relative;
}

}