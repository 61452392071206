@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-mv
/  --------------------------------------------------------------*/

.main-visual {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url(../img/top/bg_main.png) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  &__logo {
    margin: 0 0 40px;
  }
  &__description {
    margin-bottom: 0;
    padding-bottom: 10px;
    font-size: rem(22);
    font-family: 'Noto Sans Japanese', Sans-Serif;
  }
  &__text {
    display: inline-block;
    padding: 30px 0;
    font-size: rem(19);
    color: #000;
    font-weight: bold;
    background-image : url(../img/top/main_line.png),url(../img/top/main_line.png);
    background-repeat: repeat-x;
    background-position: left top,left bottom;
    line-height: 2.5;
    text-align: center;
    text-shadow: 0px 0px 6px #fff;
  }

/* for sp  */
@include media(sp) {
  height: 180px;
  background-position: center -20px;
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
  }
  &__logo {
    width: 190px;
    margin: 0 0 10px;
  }
  &__description {
    padding: 0 20px;
    font-size: rem(14);
    text-align: center;
  }
  &__text {
    display: none;
  }
}
}





