@charset "utf-8";

/*----------------------------------------------------
	side-menu
----------------------------------------------------*/
.side-menu {
@include media(pc) {
  position: fixed;
  top: 15%;
  right: 0;
  z-index: 100;
  &__section {
    position: relative;
    width: 242px;
    right: -230px;
    padding: 0 0px 0 50px;
    box-sizing: border-box;
    color: #4b423e;
    font-size: rem(12);
  }
  &__title {
    margin: 0;
    font-size: rem(12);
    text-align: center;
  }
  p {
    margin: 0 0 5px;
  }
  a[href^="tel"] {
    color: #4b423e;
  }
  a[href^="mailto"] {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &__facebook {
    padding-left: 22px;
    background: url(../img/share/icon_facebook.png) no-repeat;
    background-size: 16px auto;
    a {
      color: #4b423e;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__headline {
    display: inline-block;
    margin: 0 0 5px;
    padding: 5px;
    background: url(../img/share/bg_sub_title.png);
    font-size: rem(12);
    line-height: 1;
  }
  .-sub01 {
    padding-top: 84px;
    padding-bottom: 90px;
    background: url(../img/share/bg_sub01.png) no-repeat;
    background-size: 100%;
    .side-menu__title {
      margin: 0 0 5px;
    }
    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 116px;
      height: 131px;
      left: -100px;
      background: url(../img/share/img_sub01.png) no-repeat;
    }
  }
  .-sub02 {
    padding-top: 60px;
    padding-bottom: 70px;
    background: url(../img/share/bg_sub02.png) right top no-repeat;
    //background-size: cover;
    .side-menu__title {
      margin: 0 0 5px;
    }
    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 109px;
      height: 175px;
      top: 0;
      left: -90px;
      background: url(../img/share/img_sub02.png) no-repeat;
    }
  }
}
/* スマホサブメニュー */

@include media(sp) {
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  //flex-direction: column;
  width: 100%;
  border-top: 1px solid #d4d4d4;
  &__title {
    margin: 0;
    //padding: 10px 5px;
    //width: 30%;
    font-size: rem(11);
    font-weight: normal;
    box-sizing: border-box;
    img,br {
      display: none;
    }
    br[break="sp"] {
      display: block;
    }
  }
  &__section {
    display: flex;
  }
  a {
    color: #fff;
  }
  p {
    margin: 0;
  }
  &__tel {
    display: flex;
    //width: 70%;
    align-items: center;
    //padding: 5px;
    justify-content: center;
    box-sizing: border-box;
    font-weight: bold;
    a:after {
      display: inline-block;
      font-family: fontAwesome;
      content: "\f098";
      font-size: 30px;
      line-height: 1;
    }
    span {
      display: none;
    }
  }
  &__mail {
    display: none;
  }
  .-sub01 {
    width: 33%;
    padding: 5px 10px;
    border-right: 1px solid #fff;
    background: #eb5b64;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .side-menu__title {
      //display: none;
      //margin: 0;
      //border-right: 1px solid #fff;
      margin-right: 10px;
      text-align: center;
      font-size: rem(10);
    }
    .side-menu__headline {
      display: none;
    }
  }
  .-sub02 {
    width: 67%;
    background: #fff;
    .side-menu__title {
      display: none;
    }
    .side-menu__detail-kids {
      order: 1;
      text-align: center;
      background: #b0555f;
      color: #fff;
      padding: 5px 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      line-height: 1;
    }
    .side-menu__text-kids {
      width: 100%;
      order: 2;
      font-size: rem(10);
      padding: 5px 5px;
      padding-right: 0;
      display: flex;
      align-items: center;
    }
    .side-menu__headline {
      margin: 0 0 5px;
      font-size: rem(10);
      font-weight: normal;
    }
    .side-menu__price {
      margin: 0;
      //font-weight: bold;
      font-size: rem(14);
    }
  }
}
}

