@charset "utf-8";

/* ----------------------------------------------------------------
   component/ttl
/  --------------------------------------------------------------*/

.headline {
  margin-bottom: 20px;
  padding-left: 35px;
  padding-bottom: 30px;
  background: url(../img/share/line_headline.png) left bottom repeat-x;
  font-size: rem(30);
  &.-type-r {
    padding-top: 50px;
    padding-left: 74px;
    background: url(../img/share/ribbon02.png) no-repeat;
  }
  &.-type-photo {
    padding-left: 20px;
    &:before {
      display: inline-block;
      content: " ";
      width: 42px;
      height: 30px;
      background: url(../img/share/icon_camera.png) no-repeat;
      margin-right: 25px;
    }
  }
@include media(sp) {
  padding-left: 0;
  padding-bottom: 10px;
  font-size: rem(20);
  font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
  &.-type-r {
    padding: 20px 20px 0;
    background: url(../img/share/ribbon02.png) no-repeat;
    background-size: 48px 46px;
  }
}
}

