@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-flow
/  --------------------------------------------------------------*/

.top-flow {
  padding: 80px 0 90px;
  background: #fff7f3;
  &__contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &-section {
    width: 350px;
    margin-bottom: 60px;
    text-align: center;
    &__img {
      position: relative;
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      &-number {
        position: absolute;
        left: 0;
        width: 88px;
        height: 88px;
        background: #f7dacf;
        border-radius: 50%;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &-text {
        position: absolute;
        top: 90px;
        right: -73px;
        img {
          max-width: inherit;
        }
        &:after {
          position: absolute;
          right: -18px;
          bottom: 0;
          display: block;
          content: " ";
          width: 91px;
          height: 16px;
          background: url(../img/top/arrow.png) no-repeat;
          background-size: contain;
        }
      }
    }
    &:nth-child(even) {
      .top-flow-section__img {
        &-text {
          &:after {
            right: 20px;
            top: 60px;
            transform: rotate(150deg);
          }
        }
      }
    }
    &:last-child {
      .top-flow-section__img {
        &-text {
          &:after {
            display: none;
          }
        }
      }
    }
    &__title {
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-size: rem(18);
      text-align: center;
      background: url(../img/top/line_flow.png) left bottom no-repeat;
    }
    &__text {
      p {
        color: #000;
        text-align: left;
      }
    }
  }
  &__detail-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    li {
      position: relative;
      width: 32%;
      a {
        color: #fff;
        width: 100%;
        height: 147px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        &:after {
          position: absolute;
          right: 5px;
          bottom: 5px;
          content: "〉";
          background: #fff;
          color: #aa4451;
          border-radius: 50%;
          width: 49px;
          height: 49px;
          text-align: center;
          font-size: 19px;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 8px;
          box-sizing: border-box;
        }
      }
      &.-service01 a {
        background-image: url(../img/top/bnr_detail01.jpg);
        
      }
      &.-service02 a {
        background-image: url(../img/top/bnr_detail02.jpg);
      }
      &.-service03 a {
        background-image: url(../img/top/bnr_detail03.jpg);
      }
    }
  }
/* for sp  */
@include media(sp) {
  padding: 20px 0;
  &__contents {
    flex-direction: column;
  }
  &-section {
    width: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &__img {
      flex: 0 0 50%;
      margin: 0;
      &-number {
        width: 44px;
        height: 44px;
      }
      &-text {
        top: auto;
        bottom: 10px;
        right: -10px;
        img {
          width: 76px;
        }
        &:after {
          right: 0;
          bottom: -15px;
          width: 46px;
          height: 8px;
          transform: rotate(-310deg);
        }
      }
    }
    &__title {
      font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
      font-size: rem(16);
    }
    &__text {
      margin-left: 20px;
      p {
        font-size: rem(13);
      }
    }
    &:nth-child(even) {
      .top-flow-section__img {
        order: 2;
        &-text {
          &:after {
            top: auto;
            bottom: -10px;
          }
        }
      }
      .top-flow-section__text {
        order: 1;
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .-flow01 {
    .top-flow-section__img {
      &-text {
        img {
          width: 76px;
        }
      }
    }
  }
  .-flow02 {
    .top-flow-section__img {
      &-text {
        right: 10px;
        img {
          width: 40px;
        }
      }
    }
  }
  .headline {
    margin: 0 -20px;
    padding: 10px;
    background: #aa4451;
    color: #fff;
    font-size: rem(14);
    text-align: center;
    font-family: "Yu Mincho", YuMincho,'Montserrat',sans-serif;
  }
  &__detail-list {
    display: block;
    width: auto;
    margin: 0 -20px;
    li {
      width: auto;
      a {
        height: 100px;
        background-position: left center;
        &:after {
          bottom: auto;
          top: 50%;
          margin-top: -10px;
          width: 25px;
          height: 25px;
          font-size: 10px;
          padding-right: 4px;
        }
      }
    }
  }
}
}