@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-news
/  --------------------------------------------------------------*/

.top-news {
  padding: 10px 0 60px;
  background: #f7dacf;
  overflow: hidden;
  .contents-inner {
    width: 790px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .facebook {
    background: #fff;
    width: 440px;
    height: 414px;
    overflow: hidden;
    .fb-page.fb_iframe_widget {
      overflow: hidden;
    }
  }
  &__bnr {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 0;
    li {
      margin-bottom: 3px;
    }
  }
/* for sp  */
@include media(sp) {
  padding-bottom: 0;
  .contents-inner {
    width: auto;
    display: block;
  }
  .facebook {
    width: 95%;
    height: 250px;
    margin: 0 auto 2.5%;
  }
  &__bnr {
    margin: 0 auto 30px;
    li {
      text-align: center;
    }
  }
}
}