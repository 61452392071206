@charset "utf-8" ;

/* ----------------------------------------------------------------
   layout/content
/  --------------------------------------------------------------*/

.content {
  position: relative;
  //padding-bottom: 140px;
@include media(sp) {
  padding-bottom: 0;
}
}

.contents-inner {
  position: relative;
  width: 88%;
  margin: 0 auto;
  @include media(mid) {
    width: 900px;
  }
}


.flex-contents {
  display: flex;
}

