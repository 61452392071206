@charset "utf-8";

/* ----------------------------------------------------------------
   layout/main
/  --------------------------------------------------------------*/

main {
  background: #fff7f3;
  overflow: hidden;
  dl {
    overflow: hidden;
    dt {
      float: left;
      clear: left;
    }
    dd {
      margin: 0;
      padding: 0;
    }
  }
  
}

.-top main {
  background: none;
  overflow: visible;
}
