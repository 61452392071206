@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-profile
/  --------------------------------------------------------------*/

.top-profile {
  padding-top: 20px;
  padding-bottom: 80px;
  text-align: center;
  &__img {
    background: url(../img/top/bg_profile_photo.png) center top no-repeat;
    text-align: center;
    font-size: rem(20);
    padding: 25px 0;
    background-size: contain;
  }
  &__text {
    width: 712px;
    margin: 0 auto;
    line-height: 3;
    //font-weight: bold;
  }
@include media(sp) {
  padding-top: 90px;
  padding-bottom: 40px;
  background: url(../img/share/ribbon.png) center 20px no-repeat;
  background-size: 170px 54px;
  .contents-inner {
    display: flex;
    flex-direction: column;
  }
  .section-title {
    order: 2;
    margin-top: 0;
    &:before {
      display: none;
    }
  }
  &__img {
    order: 1;
    margin-bottom: 0;
    img {
      width: 120px;
    }
  }
  &__text {
    width: auto;
    line-height: 1.6;
    order: 3;
  }
}
}
