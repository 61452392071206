@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-access
/  --------------------------------------------------------------*/

.top-access {
  padding-top: 30px;
  margin-bottom: 200px;
  background: url(../img/top/bg_shop.jpg) no-repeat;
  background-size: cover;
  .contents-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__map {
    flex: 0 0 46%;
    order: 1;
    p {
      text-align: right;
      a {
        color: #000;
      }
    }
  }
  .googlemap {
    position: relative;
    padding-bottom: 92%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  &__info {
    flex: 0 0 52%;
    height: 443px;
    margin-top: -15px;
    margin-right: -15px;
    margin-bottom: 50px;
    background: url(../img/top/bg_shop_info.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
    &-detail {
      dt {
        color: #000;
      }
      dd {
        margin: 0 0 15px;
        span {
          display: block;
          text-indent: -1em;
          padding-left: 4.5em;
          &:before {
            content: ":";
            margin-right: .5em;
          }
        }
      }
      dd + dd {
        margin-top: -15px;
      }
    }
  }
  &__title {
    text-align: center;
    font-size: rem(20);
    line-height: 1;
    &-ruby {
      font-size: rem(12);
    }
  }
  &__route {
    width: 100%;
    list-style: none;
    margin: 0 0 -15%;
    padding: 0;
    display: flex;
    justify-content: space-between;
    order: 3;
    li {
      position: relative;
      width: 228px;
      color: #000;
      &:after {
        position: absolute;
        right: -100px;
        top: 30%;
        display: inline-block;
        content: " ";
        width: 91px;
        height: 16px;
        background: url(../img/share/arrow_fff.png) no-repeat;
      }
      &:last-child:after {
        display: none;
      }
      img {
        margin-bottom: 10px;
      }
    }
  }
@include media(sp) {
  margin-bottom: 0;
  padding: 1px 0 0;
  background: #fff7f3;
  .contents-inner {
    display: block;
  }
  &__map {
  }
  .googlemap {
    padding-bottom: 56.25%;
  }
  &__info {
    margin: 0 -20px;
    height: auto;
    background: #fff;
    border-top: 1px solid #d4d4d4;
    &-detail {
      border-top: 1px solid #d4d4d4;
      display: flex;
      flex-wrap: wrap;
      font-size: rem(13);
      margin: 0;
    }
    dt {
      width: 20%;
      padding: 5px;
      float: none;
      border-bottom: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;
      box-sizing: border-box;
    }
    dd {
      width: 80%;
      padding: 5px;
      margin: 0;
      border-bottom: 1px solid #d4d4d4;
      box-sizing: border-box;
      span {
        padding: 0;
        text-indent: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  &__route {
    margin: 0;
    order: 2;
    flex-wrap: wrap;
    li {
      width: 46%;
      margin-bottom: 10px;
      font-size: rem(12);
      &:after {
        display: none;
      }
      img {
        margin-bottom: 5px;
      }
    }
  }
}
}
